import React, {useEffect, useState} from 'react';
import {MDBBadge, MDBBtn, MDBSpinner} from "mdb-react-ui-kit";
import axios from "axios";
import ButtonHeader from "../Header/ButtonHeader";
import GetPodjezdService from "./GetPodjezdService";
import {Button} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const AddPodjezdSite = (props) => {
    const [pods, setPods] = useState(["-","1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30"]);
    const [currpod, setCurrpod] = useState("-");
    const [currKod, setcurrKod] = useState("");
    const [addbutton, setAddbuttob] = useState(false);

    // const getPodjezdItems = async () => {
    //     const response = await GetPodjezdService.getPodjezds(props.zid);
    //     setPods(response)
    // }
    // useEffect(() => {
    //     // getPodjezdItems()
    // }, [])
    function selectPod(response){
       console.log(response.target.id)
        setCurrpod(response.target.id)
    }

    function updCod(response){
        setcurrKod(response.target.value)
        console.log(response.target.value)
        if ((response.target.value).length>3){
            setAddbuttob(true)
        } else {
            setAddbuttob(false)
        }
    }


    function add() {
        let zid = encodeURIComponent(props.zid);
        let crpd = encodeURIComponent(currpod);
        let curcodr = encodeURIComponent(currKod);
        // let url = `http://example.com/page?param1=${param1}&param2=${param2}`;
        axios.get(`https://functions.yandexcloud.net/d4e95f85fclumbip2fui?MyZID=${zid}&MyPID=${crpd}&MyKod=${curcodr}`)
            //     // .then(response => this.setState({ updatedAt: response.data.updatedAt }));
            //     .then(response => setGoods(response.good))
            .then(alert("Код передан на модерацию. Спасибо."))

        // {props.zid}
    }


    if (!pods || pods.length === 0)
        return <>
            <div className='text-center'>
                <MDBSpinner className='mx-2' color='warning'>
                    <span className='visually-hidden'>Loading...</span>
                </MDBSpinner>
            </div>
        </>

    return (
        <>
            <h3></h3>
            <ButtonHeader value={"Код в какой подъезд нужно добавить?"} isnew={false} />
            <div className="d-flex p-1 justify-content-center flex-shrink-5 flex-wrap">
                {

                    pods.map((podgroup, index) =>
                        <div className="d-flex p-1 justify-content-center flex-shrink-5">
                            {(podgroup == currpod) ? (<Button onClick={selectPod} id={podgroup} key={podgroup}
                                                              variant="success">{podgroup}</Button>) : (
                                <Button id={podgroup} onClick={selectPod} key={podgroup}>{podgroup}</Button>)}
                        </div>
                    )

                }
                {("-" == currpod) ? (<div><a>Сначала нужно выбрать подъезд выше</a></div>) : (
                    <div className="mb-3 p-3">

                        {/*<ButtonHeader value={"Укажите код от подъезда"} isnew={false}/>*/}
                        <MDBBtn className='mx-2' style={{backgroundColor: '#f87300'}}>
                            Укажите код от {currpod} подъезда

                        </MDBBtn>


                        <InputGroup className=" p-3">
                            <InputGroup.Text>Код: </InputGroup.Text>
                            <Form.Control
                                aria-label="Amount (to the nearest dollar)"
                                // defaultValue={currKod}
                                value={currKod}
                                onChange={updCod}
                            />
                        </InputGroup>
                        {(addbutton)? (
                            <div className="d-grid gap-2">
                                <MDBBtn onClick={add} className='mx-2' style={{backgroundColor: '#004c94'}}>
                                    Добавить код в базу
                                </MDBBtn>
                            </div>
                        ) : (<a>Введите код подъезда</a>)}
                    </div>
                )}

            </div>
        </>
    )
        ;
};

export default AddPodjezdSite;